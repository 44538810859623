<template>
  <div>
    <b-modal
      ok-title="Save"
      ok-only
      centered
      title="Create Word"
      v-model="isOpen"
      @ok="save"
      id="modal-xl"
      size="xl"
    >
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="word.thumbnail" class="border" />
          </b-col>
          <b-col cols="9">
            <b-col cols="12">
              <b-form-group label="Content" label-for="v-content">
                <b-form-input
                  class="mb-3"
                  type="text"
                  v-model="word.content"
                  placeholder="Enter your title here"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Level" label-for="v-level">
                <b-form-select v-model="word.level" :options="levels" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Audio" label-for="v-audio">
                <ui-component :component-data="word.audio" class="border" />
              </b-form-group>
            </b-col>
          </b-col>
        </b-row>
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import service from "../../service";

export default {
  props: {
    word: { type: Object, required: true },
    levels: { type: Array, required: true },
  },
  data() {
    return {
      isOpen: false,
      isUpdate: false,
    };
  },
  methods: {
    openModal(update = null) {
      this.isOpen = true;
      if (update) {
        this.isUpdate = true;
      }
    },
    async save() {
      if (this.isUpdate) {
        this.updateWord();
      } else {
        let response = await service.createWord(this.word);
        if (response.data.type === "DATA") {
          this.$store.dispatch("pushSuccessNotify", {
            text: "Successfully created word!",
          });
        } else {
          this.$store.dispatch("pushErrorNotify", {
            text: "Error created word!",
          });
        }
      }
      this.$emit("refreshWord");
    },
    async updateWord() {
      await service.updateWord({ data: JSON.stringify(this.word) });
      this.$emit("refreshWord");
      this.isUpdate = false;
    },
  },
};
</script>
