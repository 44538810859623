export default {
  content: "",
  thumbnail: {
    type: 'image',
    value: null,
    meta: {
      alt: null,
      redirect_link: null,
    },
  },
  audio: {
    type: 'audio',
    value: null,
    meta: {
      alt: null,
      redirect_link: null,
    },
  },
  level: 1,
  topic_id: null,
  _id: null,
  createdAt: null,
  updatedAt: null,
}
