<template>
  <div>
    <b-row class="mb-1">
      <b-col class="mt-1" cols="4">
        <h2 class="h2">Words</h2>
      </b-col>
      <b-col class="mt-1" cols="5">
        <b-button
          pill
          class="ml-1"
          :variant="selected_level === 1 ? 'outline-primary' : 'outline-dark'"
          @click="selected_level = 1"
          >A1
        </b-button>
        <b-button
          pill
          class="ml-1"
          :variant="selected_level === 2 ? 'outline-primary' : 'outline-dark'"
          @click="selected_level = 2"
          >A2
        </b-button>
        <b-button
          pill
          class="ml-1"
          :variant="selected_level === 3 ? 'outline-primary' : 'outline-dark'"
          @click="selected_level = 3"
          >B1
        </b-button>
        <b-button
          pill
          class="ml-1"
          :variant="selected_level === 4 ? 'outline-primary' : 'outline-dark'"
          @click="selected_level = 4"
          >B2
        </b-button>
      </b-col>
      <b-col class="text-right mt-1" cols="3">
        <b-button variant="primary" @click="addWord(null)">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Add Word</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="word in words"
        :key="word._id"
      >
        <word-card
          :word="word"
          @confirmDelete="confirmDelete"
          @updateWord="addWord"
        />
      </b-col>
    </b-row>
    <word-create-modal
      @refreshWord="refreshWord"
      :levels="levels"
      ref="modalWord"
      :word="wordCurrent"
    />
    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import service from "../../service";
import WordCard from "./WordCard.vue";
import WordCreateModal from "./WordCreateModal";
import { resetObject } from "@core/utils";
import defaultObjectWord from "../../defaultObjectWord";

export default {
  components: {
    WordCard,
    WordCreateModal,
  },
  props: {
    topic_id: { type: String, required: true },
    levels: { type: Array, required: true },
  },
  data() {
    return {
      words: [],
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
      wordCurrent: { type: Object },
      selected_level: 1,
    };
  },
  watch: {
    current_page() {
      this.getListWord();
    },
    selected_level() {
      this.getListWord();
    },
  },
  created() {
    this.getListWord();
  },
  methods: {
    async getListWord() {
      let response = await service.getWordList({
        page: this.current_page,
        limit: this.items_perpage,
        query: encodeURIComponent(
          JSON.stringify({
            topic_id: this.topic_id,
            level: this.selected_level,
          })
        ),
      });

      if (response.data.type === "DATA") {
        this.words = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
    async deleteWord(word_id) {
      let response = await service.deleteWord({ id: word_id });
      if (response.data.type === "SUCCESS") {
        this.getListWord();
      }
    },
    refreshWord() {
      this.getListWord();
    },
    async addWord(word_id = null) {
      if (word_id) {
        let indexWord = this.words.findIndex((item) => item._id == word_id);
        if (indexWord >= 0) {
          this.wordCurrent = this.words[indexWord];
        }
      } else {
        resetObject(this.wordCurrent, { ...defaultObjectWord });
      }
      this.wordCurrent.topic_id = this.topic_id;
      this.$refs.modalWord.openModal(word_id);
    },
    confirmDelete(word_id) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteWord(word_id);
          }
        });
    },
  },
};
</script>
