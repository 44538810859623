<template>
  <b-card class="text-center">
    <template slot="header">
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          flex-grow-1
          w-100
        "
      >
        <div style="position: absolute; right: 5px">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="text-body align-middle"
              />
            </template>
            <b-dropdown-item @click.stop="updateWord(word._id)">
              <feather-icon icon="Edit2Icon" class="mr-50" />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item @click.stop="confirmDelete(word._id)">
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </template>
    <b-img
      width="100px"
      :src="word.thumbnail.value"
      v-if="word.thumbnail.value"
    />
    <b-img
      v-else
      :blank="true"
      blank-color="#ccc"
      alt="placeholder"
      height="172"
      width="100px"
    />
    <h3 class="mt-1 mb-0 text-truncate-2">{{ word.content || word._id }}</h3>
    <small class="d-block mt-2" style="color: #bbb">
      Last edited: {{ word.updatedAt | local_time_string }}
    </small>
  </b-card>
</template>

<script>
export default {
  props: {
    word: { type: Object, required: true },
  },
  methods: {
    confirmDelete(word_id) {
      this.$emit("confirmDelete", word_id);
    },
    updateWord(word_id) {
      this.$emit("updateWord", word_id);
    },
  },
};
</script>
