<template>
  <div class="page-content" v-if="topic">
    <b-row>
      <b-col md="10">
        <page-breadcrumb
          title="Vocab Topic Detail"
          class="mb-3"
          :items="breadcrumbItems"
        />
      </b-col>
    </b-row>
    <section class="my-3">
      <h2 class="h2">Overview</h2>
      <b-card class="mt-1">
        <b-row>
          <b-col cols="3">
            <strong class="h6">Thumbnail</strong>
            <ui-component :component-data="topic.thumbnail" class="border" />
          </b-col>
          <b-col cols="3">
            <strong class="h6">Background</strong>
            <ui-component :component-data="topic.background" class="border" />
          </b-col>
          <b-col cols="6">
            <strong class="h6">Title</strong>
            <b-form-input
              class="mb-3"
              type="text"
              v-model="topic.title"
              placeholder="Enter your title here"
            />
          </b-col>
        </b-row>
      </b-card>
    </section>
    <section class="my-3">
      <word-list ref="wordList" :topic_id="topic._id" :levels="levels" />
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteTopic"
      :is-deleting="isDeleting"
    />
  </div>
</template>

<script>
import service from "../service";
import WordList from "./_components/WordList";

export default {
  components: {
    WordList,
  },
  data() {
    return {
      topic: null,
      isUpdating: false,
      isDeleting: false,
      word: { type: Object },
      levels: [
        {
          text: "A1",
          value: 1,
        },
        {
          text: "A2",
          value: 2,
        },
        {
          text: "B1",
          value: 3,
        },
        {
          text: "B2",
          value: 4,
        },
      ],
    };
  },
  computed: {
    topic_id() {
      return this.$route.params.topic_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Vocab Topic List",
          to: { name: "vocab-topic-list" },
        },
      ];
      items.push({ text: "Vocab Topic Detail", active: true });
      return items;
    },
  },
  async created() {
    await this.getTopic();
  },
  methods: {
    async getTopic() {
      this.topic = await service.get({ id: this.topic_id });
    },
    async update() {
      this.isUpdating = true;
      await service.update({ data: JSON.stringify(this.topic) });
      this.getTopic();
      this.isUpdating = false;
    },
    async deleteTopic() {
      this.isDeleting = true;
      await service.delete({ id: this.topic_id });
      this.isDeleting = false;
      this.$router.push({ name: "vocab-topic-list" });
    },
  },
};
</script>
